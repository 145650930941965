import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Pagination, EffectFade, Lazy } from "swiper";
import SlideButton from "./Slide-Button";
import { Button, Modal } from "react-bootstrap";
import banners from "../../JSON/data.json";
// import Timer2 from "./Timer2.0";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { Arrowrightw } from "../../icons";
import { useState, useEffect } from "react";

var imgs = [];

function MySwiper({ device }) {
  const REGIS_TEXT = "Regístrate";
  const REGIS_URL =
    "https://m.apuestas.codere.es/deportesEs/#/mso/RegistroNewPage";

  const [currentTycType, setCurrentTycType] = useState(null);
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);
  const [ctaText, setCtaText] = useState(REGIS_TEXT);
  const [ctaLink, setCtaLink] = useState(REGIS_URL);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper) {
      if (showTycModal) swiper.autoplay.pause();
    }
  }, [showTycModal]);

  function dateInBetween(banner) {
    return (
      new Date() >= new Date(banner.startDate) &&
      new Date() <= new Date(banner.endDate)
    );
  }
  function BannerFilter(banner) {
    if (!banner.scheduled) return banner;
    else {
      if (dateInBetween(banner)) return banner;
    }
  }

  const indexHandler = (swiper) => {
    if (imgs[swiper.realIndex].ctaText) {
      setCtaText(imgs[swiper.realIndex].ctaText);
      setCtaLink(imgs[swiper.realIndex].ctaLink);
    } else {
      setCtaText(ctaText);
      setCtaLink(ctaLink);
    }

    setCurrentTycType(imgs[swiper.realIndex].tycType || null);
    setCurrentBannerName(imgs[swiper.realIndex].name || "");
  };

  if (!device) imgs = banners.desktop_slide_list.filter(BannerFilter);
  else imgs = banners.mobile_slide_list.filter(BannerFilter);

  const modalGenerator = () => {
    return (
      <>
        <Modal
          // onWheel={tycScrollHandler}
          centered
          show={showTycModal}
          onHide={() => setShowTycModal(false)}
          contentClassName="terms-tyc"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="test">
                <h5>Términos y condiciones</h5>
                <h5>{currentBannerName}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentTycType && tycGenerator(currentTycType)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowTycModal(false)}
              className="accept-btn"
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  const tycGenerator = (type) => {
    const location = window.location.host;

    return (
      <iframe
        width="100%"
        height="400px"
        src={
          location.includes("codere.es")
            ? `https://www.codere.es/Spain/tyc/es-tyc-${type}.html` // for prd env
            : `https://blog.codere.es/wp-content/uploads/2024/08/es-tyc-${type}.html` // for testing env
        }
      />
    );
  };

  return (
    <div id="carousel-section">
      <Swiper
        onBeforeInit={(swiper) => setSwiper(swiper)}
        onSlideChange={(swiper) => indexHandler(swiper)}
        modules={[Pagination, EffectFade, Autoplay, Lazy]}
        pagination={{ clickable: true }}
        effect={"fade"}
        autoplay={{ delay: 3250, disableOnInteraction: false }}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        init={false}
      >
        {imgs.map((item, index) => (
          <SwiperSlide key={index}>
            <a href={ctaLink}>
              <img
                width={device ? "300" : "400"}
                height={device ? "410" : "140"}
                src={item.image}
                alt={item.img_alt}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      {!device ? (
        <div id="des-reg">
          <Button
            className="central-regis gl-effect"
            href={ctaLink}
            rel="nofollow"
          >
            {ctaText}
            <Arrowrightw />
          </Button>
          {currentTycType && (
            <span className="tyc" onClick={() => setShowTycModal(true)}>
              Términos y condiciones
            </span>
          )}
        </div>
      ) : (
        <div className="ReactSwipeButton mobile_regis">
          <SlideButton redgis={ctaLink} />
          {currentTycType && (
            <div className="mob-tyc">
              <span className="" onClick={() => setShowTycModal(true)}>
                Términos y condiciones
              </span>
            </div>
          )}
        </div>
      )}

      {modalGenerator()}
    </div>
  );
}
export default MySwiper;
