import { NavLink } from "react-bootstrap";
import { Arrowrightw } from "../../icons";
function BottomGame({ banner }) {
  const gameUrl = `https://m.apuestas.codere.es/deportesEs/#/casino/${banner.gameType}Page?lobby=Nacional&playgame=${banner.iniciator} ${banner.name}`;

  return (
    <NavLink href={gameUrl} rel={"nofollow"}>
      <div className="bottom-card">
        <div className="bottom-box">
          <div className="game">
            <img width="15" height="18" src={banner.image} alt={banner.name} />
          </div>
          <div className="logo">
            <img
              width="16"
              height="5"
              src={banner.logo}
              alt={`${banner.name}-logo`}
            />
          </div>
        </div>
        <div className="bottom-content ">
          <div className="bottom-game-regis game-card d-flex justify-content-center m-auto">
            <p className="m-0 text-white">Juega Ahora</p>
            <div style={{ margin: "2px 0 0 10px" }}>
              <Arrowrightw />
            </div>
          </div>
        </div>
        <div className="bottom-game-title">
          <h3>{banner.name}</h3>
        </div>
      </div>
    </NavLink>
  );
}

export default BottomGame;
