import { Facebook, Twitter, Instagram, Youtube, CodereChat } from "../icons";

import { Image, NavLink } from "react-bootstrap";

const Footer = ({ device }) => {
  const footer = [
    {
      title: "SOBRE NOSOTROS",
      itemList: [
        {
          name: "Juego más seguro",
          url: "https://www.codere.es/ayuda/juego-responsable",
          rel: "nofollow",
        },
        {
          name: "Juego Autorizado",
          url: "https://www.ordenacionjuego.es/es/op-CodereOnline",
          rel: "nofollow",
        },
        {
          name: "Contrato",
          url: "https://m.apuestas.codere.es/csbgonline/condicionesgenerales/CondicionesGeneralesOnlineNacional.pdf?_ga=1.14119028.2134564450.1475767251",
          rel: "nofollow",
          target: "_blank",
        },
        {
          name: "Reglas",
          url: "https://www.codere.es/informaci%C3%B3n-legal/reglas-particulares",
          rel: "nofollow",
        },
        {
          name: "Protección Datos Jugadores",
          url: "https://m.apuestas.codere.es/csbgonline/condicionesgenerales/CondicionesGeneralesOnlineNacional.pdf#proteccion_datos_personales",
          rel: "nofollow",
        },
        {
          name: "Política de Cookies",
          url: "https://www.codere.es/informaci%C3%B3n-legal/politica-de-cookies",
          rel: "nofollow",
        },
        {
          name: "Afiliados de Codere",
          url: "https://www.codere-partners.com/es/",
          rel: "nofollow",
          target: "_blank",
        },
        {
          name: "Política de Privacidad",
          url: "https://www.codere.es/ayuda/pol%C3%ADtica-de-privacidad",
          rel: "nofollow",
        }
      ],
    },
    {
      title: "LINKS DESTACADOS",
      itemList: [
        {
          name: "Descarga App",
          url: "https://www.codere.es/ayuda/apuesta-en-tu-movil",
        },
        {
          name: "Resultados y Estadísticas",
          url: "https://www.codere.es/ayuda/resultados-y-estadisticas",
        },
        { name: "Casino", url: "https://www.codere.es/casino" },
        {
          name: "Ruleta",
          url: "https://www.codere.es/casino/ruleta",
        },
        {
          name: "Slots",
          url: "https://www.codere.es/casino/slots",
        },
        {
          name: "Blackjack",
          url: "https://www.codere.es/casino/blackjack",
        },
        {
          name: "Jackpots",
          url: "https://www.codere.es/casino/jackpots",
        },
        {
          name: "Tarjeta Codere",
          url: "https://m.apuestas.codere.es/deportesEs/#/mso/CodereCardPage",
        },
      ],
    },
    {
      title: "DEPORTES",
      itemList: [
        {
          name: "Info Depósitos y Cobros",
          url: "https://www.codere.es/dep%C3%B3sitos/dep%C3%B3sito-online",
        },
        {
          name: "Cómo Apostar",
          url: "https://www.codere.es/apuestas/c%C3%B3mo-y-d%C3%B3nde-apostar",
          rel: "nofollow",
        },
        {
          name: "Apuestas Recomendadas",
          url: "https://www.codere.es/cuotas-deportivas",
        },
        {
          name: "Eventos Deportivos",
          url: "https://www.codere.es/eventos-deportivos",
        },
        {
          name: "Blog Codere",
          url: "https://blog.codere.es/",
          target: "_blank",
        },
        {
          name: "Deportes",
          url: "https://m.apuestas.codere.es/deportesEs/#/HomePage",
          rel: "nofollow",
        },
      ],
    },
    {
      title: "AYUDA",
      itemList: [
        { name: "Ayuda", url: "https://www.codere.es/ayuda" },
        {
          name: "T&C Web",
          url: "https://www.codere.es/informaci%C3%B3n-legal/t%C3%A9rminos-y-condiciones",
          rel: "nofollow",
        },
        {
          name: "Protección Datos Web",
          url: "https://www.codere.es/Paginas/proteccion-datos-web.aspx",
          rel: "nofollow",
        },
        {
          name: "Contacto",
          url: "mailto:apuestas@codere.com",
          rel: "nofollow",
        },
        { name: "+91 343 29 50", url: "callto:91 343 29 50", rel: "nofollow" },
        {
          name: "Online chat",
          url: "https://m.apuestas.codere.es/deportesEs/#/ContactPage",
          rel: "nofollow",
        },
      ],
    },
  ];

  const logosPrefix = "https://www.codere.es/assets1/logosAndSponsors/newImges";
  const payment = [
    {
      src: `${logosPrefix}/cs-logo_Bizum.webp`,
      name: "bizum",
    },
    {
      src: `${logosPrefix}/cs-logo_LocalCodere.webp`,
      name: "Codere",
    },
    {
      src: `${logosPrefix}/cs-logo_Visa.webp`,
      name: "visa",
    },
    {
      src: `${logosPrefix}/cs-logo_Mastercard.webp`,
      name: "MasterCard",
    },
    {
      src: `${logosPrefix}/cs-logo-TransferenciaBancaria.webp`,
      name: "Transferencia",
    },
    {
      src: `${logosPrefix}/cs-logo_Neteller.webp`,
      name: "Neteller",
    },
    {
      src: `${logosPrefix}/cs-logo_Skrill.webp`,
      name: "Skrill",
    },
    {
      src: `${logosPrefix}/cs-logo_PayPal-darkbkg.webp`,
      name: "Paypal",
    },
    {
      src: `${logosPrefix}/cs-logo_PaySafe-darkbkg.webp`,
      name: "Pay-Safe-Card",
    },
    {
      src: `${logosPrefix}/cs-logo_HalCash.webp`,
      name: "Halcash",
    },
    {
      src: `${logosPrefix}/cs-logo_RapidTransfer-darkbkg.webp`,
      name: "RapidTransfer",
    },
    {
      src: `${logosPrefix}/cs-logo-transferencia-inmediata.webp`,
      name: "Transferencia-Inmediata",
    },

  ];

  return (
    <footer className="nb-footer mb-5">
      <div className="container">
        <div className="social-media text-center mt-4">
          <div
            style={{ gap: "15px" }}
            className="list-inline d-flex justify-content-center"
          >
            <a
              href="https://m.apuestas.codere.es/deportesEs/#/ContactPage"
              rel="nofollow"
            >
              <CodereChat />
            </a>
            <a href="https://www.facebook.com/CodereApuestas/" rel="nofollow">
              <Facebook />
            </a>
            <a href="https://www.instagram.com/codereapuestas/" rel="nofollow">
              <Instagram />
            </a>
            <a href="https://twitter.com/@Codereapuestas" rel="nofollow">
              <Twitter />
            </a>
            <a
              href="https://www.youtube.com/channel/UCnHiADE4Tfzg94iTGKx9WOQ"
              rel="nofollow"
            >
              <Youtube />
            </a>
          </div>
        </div>

        <div className="apps mt-4 text-center">
          <a
            href="https://codere.onelink.me/3k3c?pid=SplashPage_ES&c=iOS&af_dp=codere%3A%2F%2F&af_web_dp=https%3A%2F%2Fwww.codere.es%2Fayuda%2Fapuesta-en-tu-movil"
            rel="nofollow"
          >
            <img
              src="https://www.codere.es/assets1/logosAndSponsors/appstore.svg"
              alt="Descarga en App Store"
            />
          </a>
          <a
            href="https://codere.onelink.me/3k3c?pid=SplashPage_ES&c=iOS&af_dp=codere%3A%2F%2F&af_web_dp=https%3A%2F%2Fwww.codere.es%2Fayuda%2Fapuesta-en-tu-movil"
            rel="nofollow"
          >
            <img
              src="https://www.codere.es/assets1/logosAndSponsors/android.webp"
              alt="Descarga en Google Play"
            />
          </a>
        </div>

        <div
          style={{ gap: "15px", width: device ? "100%" : "65%" }}
          className="d-flex flex-wrap align-items-center justify-content-center mt-3 m-auto"
        >
          {payment.map((payment, k) => (
            <Image
              width={100}
              height={"auto"}
              key={k}
              src={payment.src}
              alt={payment.name}
            />
          ))}
        </div>

        <div
          className={`d-flex ${
            device && "flex-wrap"
          } justify-content-around mt-5`}
        >
          {footer.map((section, i) => (
            <div className={`${device && "w-50"} mb-4 mb-s-2`} key={i}>
              <span className="footer-title">{section.title}</span>
              <div className="text-white">
                {section.itemList.map((item, j) => (
                  <NavLink
                    className="hover-green footer-item d-block"
                    href={item.url}
                    rel={item.rel}
                    key={j + item.name}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <hr style={{ marginBottom: "2vw" }} />

      <div className="juegas-banners text-center">
        <img
          width="100"
          height="100"
          src="https://www.codere.es/assets1/logosAndSponsors/PlayResponsible.webp"
          alt="Play Responsible"
        />
        <a
          href="https://www.ordenacionjuego.es/es/rgiaj"
          target="_blank"
          rel="nofollow"
        >
          <img
            width="100"
            height="100"
            src="https://www.codere.es/assets1/logosAndSponsors/autopro.webp"
            alt="AutoPro"
          />
        </a>
        <a href="https://www.juegoseguro.es/" target="_blank" rel="nofollow">
          <img
            width="100"
            height="100"
            src="https://www.codere.es/assets1/logosAndSponsors/JugarSeguro.svg"
            alt="JugarSeguro"
          />
        </a>
        <a href="https://jugarbien.es/" target="_blank" rel="nofollow">
          <img
            width="100"
            height="100"
            src="https://www.codere.es/assets1/logosAndSponsors/JugarBien.svg"
            alt="JugarBien"
          />
        </a>
        <a
          href="https://www.codere.es/Paginas/juego-responsable.aspx#procreg"
          target="_blank"
          rel="nofollow"
        >
          <img
            width="100"
            height="100"
            src="https://www.codere.es/assets1/logosAndSponsors/plus18.webp"
            alt="+18"
          />
        </a>
        <img
          width="100"
          height="100"
          src="https://www.codere.es/assets1/logosAndSponsors/sin-dev.webp"
          alt="Sin diversión no hay juego"
        />
      </div>

      <div id="legals">
        <a href="https://m.apuestas.codere.es/csbgonline/condicionesgenerales/politicaseguridadinformacion.pdf">
          <img
            width="50"
            height="80"
            src="https://www.codere.es/assets1/logosAndSponsors/aenor.webp"
            alt="AENOR Seguridad Información"
          />
        </a>
        <div>
          Codere Apuestas opera en España bajo Codere Online, SAU, con las
          siguientes licencias otorgadas por la DGOJ: 225-11/GA/A86346038/SGR,
          AOC/2014/002, ADC/2014/001, AHC/2014/002, 223-11/GO/ A86346038/SGR,
          MAZ/2015/032, RLT/2016/009, BLJ/2016/007.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
